import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Columns, Heading, Content, Box, Card } from "react-bulma-components"

import Layout from "../components/Layout"
import ReadMoreLink from "../components/ReadMoreLink"
import NonStretchedImage from "../components/NonStratchedImage"


const MainPage = () => {
    const data = useStaticQuery(graphql`
        query {
            markdownRemark(
                fields: {
                    sourceName: { eq: "pages" }
                }
                frontmatter: {
                    slug: { eq: "main/about/" }
                }
            )
            {
                frontmatter {
                    title
                    slug
                }
                excerpt(format: HTML)
            }
            allMarkdownRemark(
                filter: {
                    fields: {
                        sourceName: {eq: "pages"}
                    }
                    frontmatter: {
                        slug: {
                            regex: "/^services\//"
                        }
                    }
                }
                sort: {
                    fields: [frontmatter___priority]
                    order: ASC
                }
            )
            {
                edges {
                    node {
                        frontmatter {
                            title
                            featuredImage {
                                childImageSharp {
                                    fluid(maxWidth: 290) {
                                        ...GatsbyImageSharpFluid
                                        presentationWidth
                                    }
                                }
                            }
                            slug
                        }
                        excerpt(format: HTML, pruneLength: 200)
                    }
                }
            }
        }
    `)
    const aboutPage = data.markdownRemark
    const pages = data.allMarkdownRemark.edges
    return (
        <Layout title="Главная"
                slug="index">
            <Box>
                <Heading size={4}>Наша специализация:</Heading>
                <Content>
                    <div dangerouslySetInnerHTML={{ __html: aboutPage.excerpt }} />
                </Content>
                <ReadMoreLink to={aboutPage.frontmatter.slug} />
            </Box>
            <Columns multiline={true}>
                {pages.slice(0,6).map(({ node }, index) => (
                    <Columns.Column 
                        key={index}
                        className="is-4-desktop is-6-tablet is-12-mobile"
                    >
                        <Card>
                            <Card.Content>
                                <Heading size={4}>
                                    <Link to={node.frontmatter.slug}>
                                        {node.frontmatter.title}
                                    </Link>
                                </Heading>
                                <Columns>
                                    <Columns.Column>
                                        {node.frontmatter.featuredImage &&
                                            <Link to={node.frontmatter.slug}>
                                                <NonStretchedImage
                                                    fluid={node.frontmatter.featuredImage.childImageSharp.fluid}
                                                    alt={node.frontmatter.title}
                                                    title={node.frontmatter.title}
                                                />
                                            </Link>
                                        }
                                    </Columns.Column>
                                </Columns><Columns>
                                    <Columns.Column>
                                        <Content>
                                            <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                                        </Content>
                                        <ReadMoreLink to={node.frontmatter.slug} />
                                    </Columns.Column>
                                </Columns>
                            </Card.Content>
                        </Card>
                    </Columns.Column>
                ))}
            </Columns>  
            <Columns className="is-centered">
                <Columns.Column className="is-narrow">
                    <Heading size={4}>Другие услуги нашей компании:</Heading>
                    <Content>
                        <ul>
                            {pages.slice(6).map(({ node }, index) => (
                                <Link key={index} to={node.frontmatter.slug}>
                                    <li key={index}>{node.frontmatter.title}</li>
                                </Link>
                            ))}
                        </ul>
                    </Content>
                </Columns.Column>
            </Columns>
        </Layout>
    )
}

export default MainPage
